<template>
  <b-modal
    hide-footer
    hide-header
    centered
    size="md"
    ref="previewServices"
    id="previewServices">
    <div class="px-5 pb-5 pt-4">
      <!-- TITLE BY ACTION TYPE -->
      <div class="titles mb-2"><b>Lista de servicios</b></div>
      <!-- TITLE BY ACTION TYPE /-->
      <div>
        <div class="mb-3 ms-2">
          Paciente: <b>{{ patient.name || patient.patient_name }}</b>
        </div>
        <ul>
          <li
            v-for="service in patient.list_services"
            :key="'Servicio' + service.id"
            :style="`color: ${patient.list_services[0].agenda_color} !important`">
            <b>{{ service.agenda_name }}</b
            >{{ ` - ${service.name}` }}
          </li>
        </ul>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "PreviewServices",
  props: {
    patient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("newModal", {
      key: "previewServices",
      ref: this.$refs.previewServices,
    });
  },
  methods: {},
  computed: {},
};
</script>
